import compact from 'lodash/compact';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

import { CategoryID } from '../../../categories/categoriesTypes';

import { FetchMaterialsFilters } from '../../materialsTypes';

interface GetChangeMaterialsFiltersCategoriesDataCategoryType {
  id: CategoryID;
  parent?: {
    id: CategoryID;
  };
}

interface GetChangeMaterialsFiltersCategoriesDataOptions {
  materialCategory: GetChangeMaterialsFiltersCategoriesDataCategoryType | null;
  categories: GetChangeMaterialsFiltersCategoriesDataCategoryType[];
}

interface GetChangeMaterialsFiltersCategoriesDataReturn {
  changeFilters: FetchMaterialsFilters;
  resetFilters: string[];
}

function getChangeMaterialsFiltersCategoriesData({
  materialCategory,
  categories
}: GetChangeMaterialsFiltersCategoriesDataOptions): GetChangeMaterialsFiltersCategoriesDataReturn {
  // level1
  const parentCategoryId = materialCategory?.parent?.id || null;
  // level1 ? level2 : level1.0
  const currentCategoryId = materialCategory?.id || null;

  // level1 ? level3 : level2.0
  const childCategoryIds = map(
    filter(categories, (category) => category.parent?.id === currentCategoryId),
    'id'
  );

  // level1 ? null : level3
  const childrensChildrenCategoryIds = parentCategoryId
    ? []
    : map(
        filter(categories, (category) =>
          some(childCategoryIds, (childId) => category.parent?.id === childId)
        ),
        'id'
      );

  // level1 ? level2 : level1.0
  const childAsParentsCategoryIds = filter(childCategoryIds, (childId) =>
    some(categories, (category) => category.parent?.id === childId)
  );

  const materialParentCategoryIds = parentCategoryId
    ? // has level1
      compact([
        parentCategoryId,
        !isEmpty(childCategoryIds) && currentCategoryId,
        ...childAsParentsCategoryIds
      ])
    : // no level1
      undefined;

  const allChildrenCategoryIds = parentCategoryId
    ? // has level1
      childCategoryIds
    : // no level1
      uniq([...childCategoryIds, ...childrensChildrenCategoryIds]);

  const materialCategoryIds = compact([
    currentCategoryId,
    ...allChildrenCategoryIds
  ]);

  const changeFilters: FetchMaterialsFilters = {
    materialParentCategoryIds,
    materialCategoryIds
  };

  const resetFilters = compact([
    !isEmpty(materialCategoryIds) ? null : 'materialCategoryIds',
    !isEmpty(materialParentCategoryIds) ? null : 'materialParentCategoryIds'
  ]);

  return {
    changeFilters,
    resetFilters
  };
}

export default getChangeMaterialsFiltersCategoriesData;
